.overlay-background {
  background-size: auto;
  display: inline-block;
  position: absolute;
}

.overlay-label {
  color: black;
  font-family: "Roboto Condensed";
  font-size: 25px;
  background-color: transparent;
}