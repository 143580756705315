.counter {
  margin-left: 24px;
  margin-right: 24px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  //word-wrap: break-word;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  overflow: visible;
  padding: 16px;
  //margin-top: -64px;
  //margin-bottom: 32px;
  background-color: #151719;
  backdrop-filter: saturate(200%) blur(30px);
  box-shadow: 0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%);
}

// Div 0
.counter-row {
  flex-basis: 100%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}


// Div 1
.counter-div-1 {
  flex-basis: 33.333333%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 33.333333%;
  box-sizing: border-box;
  margin: 0;
  flex-direction: row;
}

// Div 2
.counter-div-2 {
  //padding: 16px;
  text-align: center;
  line-height: 1;
  opacity: 1;
  background: transparent;
  box-shadow: none;
}

// H1
.counter-h1 {
  margin: 0;
  font-family: "Roboto Slab",sans-serif;
  font-size: 3rem;
  line-height: 1.25;
  font-weight: 700;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  letter-spacing: -0.125px;
  background-image: linear-gradient(195deg, white, white);
  //display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 1;
  //margin-block-start: 0.83em;
  //margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

// H5
.counter-h5 {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  opacity: 1;
  text-transform: none;
  text-decoration: none;
  color: white;
  letter-spacing: -0.125px;
  display: block;
  //margin-block-start: 1.67em;
  //margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

// Body
.counter-body {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  opacity: 1;
  text-transform: none;
  text-decoration: none;
  color: #9CA9B2;
  display: block;
  //margin-block-start: 1em;
  //margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
